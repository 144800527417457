import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { MessageEmptyIcon, MessageFilledIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import { messageIconStyles as styles } from "./message-icon.styles";
import { AppContext } from "../../app.context";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
type MessageIconWithToolTipProps = {
  handleClick?: () => void;
  hasComments?: boolean;
  isOpen?: boolean;
};
const MessageIconWithToolTip: React.FC<MessageIconWithToolTipProps> = ({ handleClick, hasComments, isOpen }) => {
  const { t } = useTranslation();
  let tooltipText = t("Click to close comments");
  if (!isOpen) {
    tooltipText = hasComments ? t("Click to view comments") : t("Click to add comments");
  }
  const { isProductResponsesView } = useContext(AppContext);
  const classes = useStyles();
  return (
    <div className={classNames(isProductResponsesView && !hasComments && classes.hiddenIcon)}>
      <TooltipV2
        title={tooltipText}
        rootClassName={"gx-survey-responses-tool-tip"}
        placement="right"
        PopperProps={{ modifiers: { offset: { offset: "0px, 10px" } } }}
        enterDelay={400}
        enterNextDelay={400}
      >
        <div className={classes.messageIconWrapper}>
          <IconButton
            onClick={() => handleEvent(handleClick)}
            ariaLabel={isOpen ? t("close comment") : t("view comment")}
          >
            {hasComments && <MessageFilledIcon title="" />}
            {!hasComments && <MessageEmptyIcon title="" />}
          </IconButton>
        </div>
      </TooltipV2>
    </div>
  );
};

export { MessageIconWithToolTip };
