import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";
import { defaultToEmptyString, handleEvent } from "gx-npm-lib";
import { BaseTextArea, TypographyComponent } from "gx-npm-ui";
import styles from "./category-header.styles";
import { MessageIconWithToolTip } from "../../../../../../../ui/message-icon/message-icon.component";
import { ResponseFormContext } from "../../../../response-form.context";
import { headerHeights } from "../../../../../../../app.constants";
import { AppContext } from "../../../../../../../app.context";
import ListHeaderExpandButton from "../../../../../../../ui/list/header/list-header-expand-button/list-header-expand-button.component";
import ListHeader from "../../../../../../../ui/list/header/list-header/list-header.component";

const useStyles = makeStyles(() => styles);
type CategoryListHeaderProps = {
  name: string;
  headerExpand?: boolean;
  headerSetExpand?: (expand: boolean) => void;
  comment: string;
  onSaveComment: (comment: string) => void;
};

const CategoryHeader: React.FC<CategoryListHeaderProps> = ({
  comment,
  headerExpand,
  name,
  onSaveComment = null,
  headerSetExpand,
}) => {
  const [isCommentEntered, setIsCommentEntered] = useState(false);
  const [isCommentShown, setIsCommentShown] = useState(false);
  const { showAllComments } = useContext(ResponseFormContext);
  const { isProductResponsesView } = useContext(AppContext);
  const { t } = useTranslation();

  const classes = useStyles();
  const stickyPosition = headerHeights.navigation + headerHeights.vendorInfo.mediaStandard;

  useEffect(() => {
    setIsCommentShown(showAllComments);
  }, [showAllComments]);

  return (
    <ListHeader
      renderItem={(expand, setExpand) => (
        <div className={classNames(classes.headerRoot)}>
          <div className={classes.top}>
            <div className={classNames(classes.headerWrap)}>
              <ListHeaderExpandButton
                collapseLabel={t("Collapse section")}
                expand={expand}
                expandLabel={t("Expand section")}
                rootClassName="gx-survey-responses-expand-button"
                setExpand={setExpand}
                ariaLabel={expand ? t("collapse section") : t("expand section")}
              />
              <TypographyComponent styling={"h5"} boldness={"medium"} color={"carbon"}>
                {name || t("Untitled category")}
              </TypographyComponent>
            </div>
            <div className={classNames(classes.right)}>
              <MessageIconWithToolTip
                isOpen={isCommentShown}
                hasComments={!!(comment || isCommentEntered)}
                handleClick={() => setIsCommentShown((prev) => !prev)}
              />
            </div>
          </div>
          <Collapse
            in={(!isProductResponsesView && isCommentShown) || (isProductResponsesView && !!comment && isCommentShown)}
          >
            <div className={classNames(classes.bottom, showAllComments && classes.bottomShowAllComments)}>
              {isProductResponsesView && !!comment && (
                <div className={classes.commentsWrapper}>
                  <TypographyComponent rootClassName={classes.commentsTitle} styling={"p4"}>
                    {t("Comments")}
                  </TypographyComponent>
                  <TypographyComponent styling={"p3"}>{comment}</TypographyComponent>
                </div>
              )}
              {!isProductResponsesView && (
                <BaseTextArea
                  flavor="OUTLINED"
                  handleAutoSave={(value: string) => handleEvent(onSaveComment, value)}
                  helperText={t("Comments")}
                  minRows={1}
                  onChange={(e: { target: { value: string } }) => setIsCommentEntered(!!e?.target?.value)}
                  placeholder=" "
                  rootClassName="gx-survey-responses-list-header-comments"
                  value={defaultToEmptyString(comment)}
                />
              )}
            </div>
          </Collapse>
        </div>
      )}
      expand={headerExpand}
      setExpand={headerSetExpand}
      sticky={stickyPosition}
    />
  );
};

export default CategoryHeader;
