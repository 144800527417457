// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q6fIatxj8qHTK_vAv65g{background-size:cover;display:inline;height:24px;width:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/file-extension-badge/file-extension-badge.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,cAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".root {\n  background-size: cover;\n  display: inline;\n  height: 24px;\n  width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Q6fIatxj8qHTK_vAv65g`
};
export default ___CSS_LOADER_EXPORT___;
