import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { ArrowCircledIcon } from "gx-npm-icons";
import { ToolTipIconButton } from "gx-npm-ui";
import listHeaderExpandButtonStyles from "./list-header-expand-button.styles";

type ListHeaderExpandButtonProps = {
  collapseLabel?: string;
  expand?: boolean;
  expandLabel?: string;
  rootClassName?: string;
  setExpand?: (val: boolean) => void;
  ariaLabel?: string;
};
const useStyles = makeStyles(() => listHeaderExpandButtonStyles);

const ListHeaderExpandButton: React.FC<ListHeaderExpandButtonProps> = ({
  collapseLabel = "",
  expand,
  expandLabel = "",
  rootClassName = "",
  setExpand,
  ariaLabel = "",
}) => {
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleEvent(setExpand, !expand);
  };

  return (
    <div className={classNames("gx-exp-col-btn", classes.iconExpand, rootClassName, expand && "gx-is-expanded")}>
      <ToolTipIconButton
        ariaLabel={ariaLabel}
        onClick={handleClick}
        showTooltip={true}
        tooltipText={expand ? collapseLabel : expandLabel}
      >
        <ArrowCircledIcon />
      </ToolTipIconButton>
    </div>
  );
};

export default ListHeaderExpandButton;
